import { Card, Image, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';
import classes from './../../styles/ProductCard.module.css';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';


interface Props {
  id: string;
  title: string;
  image: string;
  price: number;
  priceAfterDiscount: number;
}

export function ProductCard({ id, image, title, price, priceAfterDiscount }: Props) {
  const matches = useMediaQuery('(max-width: 36em)');
  const { t } = useTranslation();

  return (
    <Card withBorder radius="md" p="md" className={classes.card} component={Link} to={`/product/${id}`}>
      <Card.Section style={{position: 'relative'}}>
        <Image src={image} alt={title} width={"100%"} mah={matches ? 170 : 220} mih={matches ? 180 : 220} fit={'cover'}  />
        {priceAfterDiscount > 0
          ? <Badge variant="filled" color={"#f78814"} size={matches ? "md" : 'lg'} className={classes.badge} radius={0}>
            {t('homePage.ProductCard.badge')}
          </Badge>
          : null
        }
      </Card.Section>

      <Card.Section className={classes.section} mt="md">
          <Text fz="lg" fw={500} component={Link} to={`/product/${id}`} className={classes.link}>
            {title}
          </Text>
      </Card.Section>
      
      <Card.Section className={classes.section}>
        <Group gap={10} mt={matches ? 10 : 20}>
            <Text size='md' fw={'bold'} className={classes.label} c="#f78814">
              {priceAfterDiscount > 0 ? priceAfterDiscount : price} {t('homePage.currency')}
            </Text>
            {priceAfterDiscount > 0 
              ? <Text size='sm' fw={'bold'} className={classes.label} c="dimmed" td="line-through">
                  {price} {t('homePage.currency')}
                </Text>
              : null
            }
        </Group>
      </Card.Section>

      <Group flex={"auto"} mt={"xs"} align='flex-end'>
        <Button size={matches ? "xs" : "sm"} radius="md" style={{ flex: 1 }} color='#f78814' id='btn' component={Link} to={`/product/${id}`} className={classes.button}>
          {t('homePage.ProductCard.bay')}
        </Button>
      </Group>
    </Card>
  );
}