import { Text, Container, Group, Image, useMantineTheme, Title, ActionIcon, rem } from '@mantine/core';
import { IconMapPinFilled, IconPhoneFilled, IconMailFilled, IconBrandTiktok, IconBrandFacebook, IconBrandInstagram } from '@tabler/icons-react';
import classes from './../../styles/Footer.module.css';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';


export function Footer() {
    const theme = useMantineTheme();
    const matches = useMediaQuery('(max-width: 36em)');
    const { t } = useTranslation();

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <Image src={"/logo.png"} height={90} fit='contain' />
                    <Text size="xs" c="dimmed" mt={10} className={classes.description}>
                        {t('homePage.Footer.info')}
                    </Text>
                    <Text c="dimmed" size="sm" mt={10}>
                        {t('homePage.Footer.copyRight')}
                    </Text>
                </div>
                <div className={classes.groups}>
                    <div className={classes.wrapper} >
                        <Group gap={5} mt={20} wrap='nowrap'>
                            <IconMapPinFilled size={20} style={{ color: "#f78814" }} stroke={1.5} />
                            <Text component="a" href={"https://maps.app.goo.gl/BB6cSth8pexb7eVT7"} className={classes.link}>
                                {t('homePage.Footer.address')}
                            </Text>
                        </Group>
                        <Group gap={5} wrap='nowrap'>
                            <IconPhoneFilled size={20} style={{ color: "#f78814" }} stroke={1.5} />
                            <Text component="a" href={"tel:0781686087"} className={classes.link} >
                                0781686087
                            </Text>
                        </Group>
                        <Group gap={5} wrap='nowrap'>
                            <IconPhoneFilled size={20} style={{ color: "#f78814" }} stroke={1.5} />
                            <Text component="a" href={"tel:0562982731"} className={classes.link} >
                                0562982731
                            </Text>
                        </Group>
                        <Group gap={5} wrap='nowrap'>
                            <IconMailFilled size={20} style={{ color: "#f78814" }} stroke={1.5} />
                            <Text component="a" href={"mail:bougieplus16@gmail.com"} className={classes.link} >
                                bougieplus16@gmail.com
                            </Text>
                        </Group>
                    </div>
                        
                    <Group gap={5} justify="flex-start" wrap="nowrap" mt={'sm'}>
                        <ActionIcon size={matches ? "lg" : "md"} color="gray" variant="outline" radius={20}
                            component='a'
                            href='https://www.tiktok.com/@bougie.plus?_t=8lZhV6Ps1Ze&_r=1'
                        >
                            <IconBrandTiktok size={matches ? 18 : 15} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size={matches ? "lg" : "md"} color="gray" variant="outline" radius={20}
                            component='a'
                            href='https://www.facebook.com/bougieplus16?mibextid=ZbWKwL'
                        >
                            <IconBrandFacebook size={matches ? 18 : 15} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon size={matches ? "lg" : "md"} color="gray" variant="outline" radius={20}>
                            <IconBrandInstagram size={matches ? 18 : 15} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </div>
            </Container>
        </footer>
    );
}