import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { HeroHeader, Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { useMediaQuery } from '@mantine/hooks';

export function ProductsPage () {
    const matches = useMediaQuery('(max-width: 36em)');
    return (
        <>
            <HeaderSimple />
            <Products pt={matches ? 150 : 220} />
            <Footer/>
        </>
    );
}