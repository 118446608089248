import React, { useEffect, useState } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { HeroHeader, Products } from '../components/Sections';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Grid, Group, Image, SimpleGrid } from '@mantine/core';
import classes from './../styles/Categories.module.css';
import { useMediaQuery } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import axios from 'axios';

const brands = [
    {image: "/brand01.png", name: "renault"},
    {image: "/brand02.jpg", name: "audi"},
    {image: "/brand03.jpg", name: "hyundai"},
    {image: "/brand04.jpg", name: "peugeot"},
    {image: "/brand05.jpg", name: "fiat"},
    {image: "/brand06.png", name: "bmw"},
    {image: "/brand07.png", name: "cherry"},
    {image: "/brand08.jpg", name: "toyota"},
    {image: "/brand09.jpg", name: "suzuki"},
    {image: "/brand10.jpg", name: "chevrolet"},
    {image: "/brand11.png", name: "opel"},
    {image: "/brand12.png", name: "mercedes"},
    {image: "/brand13.png", name: "volkswagen"}
]

export function Categories () {
    const matches = useMediaQuery('(max-width: 36em)');
    let [categories, setCategories] = useState<any>([]);

    useEffect(() => {
        axios.get(`https://tajer-api2.qafilaty.com/api/category/`)
        .then(({data}) => {
            setCategories(data)
        })
        .catch((error) => console.log(error));
    }, [])

    return (
        <>
            <HeaderSimple />
            <Container size={'lg'} pt={matches ? "145px" : "212px"} >
                <SimpleGrid cols={{ base: 2, sm: 2, md: 4, lg: 5, xl: 6 }}>
                    {categories.map((item: any, index: number) => (
                        <Link to={`/?category=${item.name}`} preventScrollReset={true} key={index} >
                            <Group justify='center' align='center' className={classes.boxBrand} bg={"#fff"}>
                                <Image src={`https://tajer-api2.qafilaty.com/images/${item.image}`} h={"100px"} w={"100px"} fit='contain' />
                            </Group>
                        </Link>
                    ))}
                </SimpleGrid>
            </Container>
            <Footer/>
        </>
    );
}