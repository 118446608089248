import { IconBrandWhatsapp, IconInfoCircle, IconMinus, IconPlus, IconShoppingCartFilled, IconX } from '@tabler/icons-react';
import { Card, Text, Group, Button, ActionIcon, useMantineTheme, Grid, TextInput, Select, NumberInput, NumberInputHandlers, Accordion, Stack, Box, Radio, Notification, rem, Alert } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import axios from 'axios';
import Wilayas from './../../helper/wilayas.json';
import Communes from './../../helper/communes.json';
import { useTranslation } from 'react-i18next';

interface Props {
    data: any;
}

export function FormOrder({data}: Props) {
    const theme = useMantineTheme();
    const handlersRef = useRef<NumberInputHandlers>(null);
    const {onSubmit, reset, values, getInputProps, errors} = useForm({
        initialValues: {
            name: '',
            phone: "",
            state: "",
            city: "",
            quantity: 1,
            typeFee: "desk_fee",
            address: ""
        },
        validate: {
            name: (value) => (value.length < 2 ? 'الاسم غير صالح' : null),
            phone: (value: string) => (/^(05|06|07)[0-9]{8}$/.test(value) ? null : 'رقم الهاتف غير صالح'),
            state: (value) => (parseInt(value) <= 0 ? 'لم تختار ولاية بعد' : null),
            city: (value) => (parseInt(value) <= 0 ? 'لم تختار بلدية بعد' : null),
            quantity: (value) => (value < 1 ? 'الكمية يجب ان تكون 1 او اكثر' : null),
            typeFee: (value) => (value.length < 2 ? 'لم تختار نوع التوصيل بعد' : null),
            address: (value) => (value.length < 2 ? 'العنوان غير صالح' : null),
        },
        validateInputOnBlur: true,
        validateInputOnChange: true
    });
    const [allWilayas, setAllWilayas] = useState<{label: string, value: string}[]>([])
    const [allCommunes, setAllCommunes] = useState<{label: string, value: string}[]>([])
    const [delivery, setDelivery] = useState<any>(null)
    const [tealAlert, setTealAlert] = useState(false);
    const [redAlert, setRedAlert] = useState(false);
    const [hasDeliveryCompany, setHasDeliveryCompany] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (Wilayas.length > 0) {
            let newData: {label: string, value: string}[] = []
            Wilayas.map((item: any) => {
                newData.push({label: item.name, value: item.id.toString()})
            })
            setAllWilayas(newData)
        }

        
        axios.get(`https://tajer-api2.qafilaty.com/api/has-delivery-company/`)
        .then(({data}) => {
            setHasDeliveryCompany(data.status)
        })
        .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
        if (values.state && values.state !== "") {
            if (Communes.length > 0) {
                const filterdCommunes = Communes.filter((item: any) => item.fields.wilaya == values.state)
                let newData: {label: string, value: string}[] = []
                filterdCommunes.map((item: any) => {
                    newData.push({label: item.fields.name, value: item.pk.toString()})
                })
                setAllCommunes(newData)
            }

            axios.get(`https://tajer-api2.qafilaty.com/api/deliveryfees/${values.state}`)
            .then(({data}) => {
                setDelivery(data)
            })
            .catch((error) => console.log(error));
        }
    }, [values.state])

    const onSubmitForm = ({name, phone, state, city, quantity, typeFee, address}: any) => {
        const {_id, price, priceAfterDiscount} = data

        const stateName = allWilayas.filter(item => item.value === state)
        const cityName = allCommunes.filter(item => item.value === city)

        axios.post('https://tajer-api2.qafilaty.com/api/orders', {
            "fullName": name,
            "phone": phone,
            "state": stateName[0].label,
            "city": cityName[0].label,
            
            "typeFee": typeFee,
            "address": address,
            "deliveryPrice": (values.typeFee === "desk_fee" ? delivery?.data[0].desk_fee : delivery?.data[0].home_fee) || 0,

            "price": priceAfterDiscount > 0 ? priceAfterDiscount : price,
            "quantity": quantity,
            "idProduct": _id
        })
        .then(({data}) => {
            console.log(data);
            setTealAlert(true)
            setRedAlert(false)
            onReset()
        })
        .catch((error) => {
            console.log(error)
            setTealAlert(false)
            setRedAlert(true)
        });
    }

    const onReset = () => {
        reset()
    }

    return (
        <Card withBorder radius="md" p={30} style={{border: `2px solid ${"#f78814"}`}}>
            <Text ta={'center'} fw={700} >
                {t('productPage.FormOrder.title')}
            </Text>

            <form onSubmit={onSubmit(onSubmitForm)}>
                <Grid mt={30}>
                    <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                        <TextInput
                            placeholder={t('productPage.FormOrder.placeholder01')}
                            size='lg'
                            styles={{
                                input: {
                                    border: `2px solid #f788147d`
                                },
                            }}
                            {...getInputProps('name')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                        <TextInput
                            placeholder={t('productPage.FormOrder.placeholder02')}
                            size='lg'
                            styles={{
                                input: {
                                    border: `2px solid #f788147d`
                                },
                            }}
                            {...getInputProps('phone')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                        <Select
                            placeholder={t('productPage.FormOrder.placeholder03')}
                            data={allWilayas}
                            size='lg'
                            styles={{
                                input: {
                                    border: `2px solid #f788147d`
                                },
                                dropdown: {
                                    boxShadow: "0px 1px 11px 2px rgb(0 0 0/15%)"
                                }
                            }}
                            {...getInputProps('state')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                        <Select
                            placeholder={t('productPage.FormOrder.placeholder04')}
                            data={allCommunes}
                            size='lg'
                            styles={{
                                input: {
                                    border: `2px solid #f788147d`
                                },
                                dropdown: {
                                    boxShadow: "0px 1px 11px 2px rgb(0 0 0/15%)"
                                }
                            }}
                            {...getInputProps('city')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, sm: 12, md: hasDeliveryCompany ? 6 : 12 }}>
                        <TextInput
                            placeholder={t('productPage.FormOrder.placeholder05')}
                            size='lg'
                            styles={{
                                input: {
                                    border: `2px solid #f788147d`
                                },
                            }}
                            {...getInputProps('address')}
                        />
                    </Grid.Col>
                    {hasDeliveryCompany
                        ? <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                            <Select
                                placeholder={t('productPage.FormOrder.placeholder06')}
                                data={[
                                    {label: t('productPage.FormOrder.select.label01'), value: "desk_fee"},
                                    {label: t('productPage.FormOrder.select.label02'), value: "home_fee"}
                                ]}
                                size='lg'
                                styles={{
                                    input: {
                                        border: `2px solid #f788147d`
                                    },
                                    dropdown: {
                                        boxShadow: "0px 1px 11px 2px rgb(0 0 0/15%)"
                                    }
                                }}
                                {...getInputProps('typeFee')}
                            />
                        </Grid.Col>
                        : null
                    }
                    
                    <Grid.Col span={{ base: 12, sm: 12, md: 3 }}>
                        <NumberInput
                            handlersRef={handlersRef}
                            {...getInputProps('quantity')}
                            min={1}
                            size='lg'
                            styles={{
                                input: {
                                    border: `2px solid #f788147d`,
                                    color: theme.colors.dark[3],
                                    fontWeight: 600,
                                    paddingInlineStart: 40,
                                    paddingInlineEnd: 40,
                                    textAlign: 'center'
                                }
                            }}
                            rightSection ={
                                <ActionIcon
                                    variant="transparent" h={"100%"} w={40} radius={0} 
                                    style={{borderRight: `2px solid #f788147d`}}
                                    onClick={() => handlersRef.current?.decrement()}
                                >
                                    <IconMinus size={18} color={theme.colors.dark[2]} stroke={2} />
                                </ActionIcon>
                            }
                            rightSectionWidth={40}
                            leftSection={
                                <ActionIcon 
                                    variant="transparent" h={"100%"} w={40} radius={0} 
                                    style={{borderLeft: `2px solid #f788147d`}}
                                    onClick={() => handlersRef.current?.increment()}
                                >
                                    <IconPlus size={18} color={theme.colors.dark[2]} stroke={2} />
                                </ActionIcon>
                            }
                            leftSectionWidth={40}
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, sm: 12, md: 9 }}>
                        <Button 
                            type='submit' fullWidth variant="filled"
                            size='lg' color='#f78814' disabled={Object.keys(errors).length > 0}
                        >{t('productPage.FormOrder.button')}</Button>
                    </Grid.Col>
                </Grid>
            </form>

            <Group justify='center' mt={20}>
                <Button
                    variant="filled" radius={'lg'} color={'green'}
                    leftSection={<IconBrandWhatsapp size={18} />}
                    component='a'
                    href='https://wa.me/213781686087'
                >{t('productPage.FormOrder.labelWhatsapp')}</Button>
            </Group>
            {tealAlert ? <Alert variant="light" my="md" color="teal" title={t('productPage.FormOrder.alert01')} icon={<IconInfoCircle />} /> : null}
            {redAlert ? <Alert variant="light" my="md" color="red" title={t('productPage.FormOrder.alert02')} icon={<IconInfoCircle />} /> : null}

            <Accordion
                mt={20} variant="filled" defaultValue="1"
                style={{background: theme.colors.blue[0]}}
                styles={{
                    label: {
                        fontWeight: 700,
                        color: theme.colors.gray[7]
                    },
                    icon: {
                        color: "#8dddbd"
                    },
                    content: {
                        padding: 0
                    }
                }}
            >
                <Accordion.Item value="1" style={{background: theme.colors.blue[0]}}>
                    <Accordion.Control style={{borderBottom: `2px solid #f788147d`}} icon={<IconShoppingCartFilled size={26} /> } >{t('productPage.FormOrder.orderTitle')}</Accordion.Control>
                    <Accordion.Panel p={10}>
                        <Stack gap={0}>
                            <Group justify='space-between' style={{borderBottom: `2px dotted #f788147d`}} py={10}>
                                <Text flex={4} fw={700} c={"gray.7"} >{data?.name}</Text>
                                
                                <Text flex={1} fw={700} c={"gray.7"} >
                                    <Text span={true} size='xs' fw={700} c={theme.white} bg={"#f78814"} p={2} >{values.quantity}×</Text> {" "}
                                    {data?.priceAfterDiscount > 0 ? data?.priceAfterDiscount : data?.price} {t('productPage.currency')}
                                </Text>
                            </Group>
                            
                            {hasDeliveryCompany
                                ? <Group justify='space-between' align='center' style={{borderBottom: `2px dotted #f788147d`}} py={10}>
                                    <Text flex={4} fw={700} c={"gray.7"} >{t('productPage.FormOrder.price')}</Text>
                                    <Box flex={1}>
                                        {data?.freeShipping
                                            ? <Text fw={700} size='sm' maw={"fit-content"} c={theme.white} bg={"#f78814"} p={5} >
                                                {t('productPage.FormOrder.freePrice')}
                                            </Text>
                                            : <Text fw={700} size='sm' maw={"fit-content"} c={theme.white} bg={"#f78814"} p={5} >
                                                {(values.typeFee === "desk_fee" ? delivery?.data[0].desk_fee : delivery?.data[0].home_fee) || 0} {t('productPage.currency')}
                                            </Text>
                                        }
                                    </Box>
                                </Group>
                                : null
                            }
                            
                            <Group justify='space-between' py={10}>
                                <Text flex={4} fw={700} c={"gray.7"} >{t('productPage.FormOrder.totalPrice')}</Text>
                                
                                <Box flex={1}>
                                    <Text fw={700} c={"#f78814"} >
                                        {hasDeliveryCompany && data?.freeShipping 
                                            ? (values.quantity * (data?.priceAfterDiscount > 0 ? data?.priceAfterDiscount : data?.price))
                                            : ((values.quantity * (data?.priceAfterDiscount > 0 ? data?.priceAfterDiscount : data?.price)) + ((values.typeFee === "desk_fee" ? delivery?.data[0].desk_fee : delivery?.data[0].home_fee) || 0))
                                        } {t('productPage.currency')}
                                    </Text>
                                </Box>
                            </Group>
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Card>
    );
}