import { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Grid, Group, Image, Input, Select, SimpleGrid, Text, TextInput } from '@mantine/core';
import { useDisclosure, useMediaQuery, useWindowScroll } from '@mantine/hooks';
import classes from './../../styles/HeroHeader.module.css';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { useTranslation } from 'react-i18next';

export function HeroHeader() {
    const autoplay = useRef(Autoplay({ delay: 2000 }));
    const autoplayHeader = useRef(Autoplay({ delay: 5000 }));
    let location = useLocation();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const matches = useMediaQuery('(max-width: 36em)');
    let [allCategories, setAllCategories] = useState<any[]>([]);
    let [allMainCategories, setAllMainCategories] = useState<{label: string, value: string}[]>([]);
    let [allSubCategories, setAllSubCategories] = useState<string[]>([]);
    let [category, setCategory] = useState<string | null>("");
    let [subCategory, setSubCategory] = useState<string | null>("");
    let [search, setSearch] = useState<string>("");
    const { t, i18n } = useTranslation();
    const [scroll, scrollTo] = useWindowScroll();

    useEffect(() => {
        axios.get(`https://tajer-api2.qafilaty.com/api/category`)
        .then(({data}) => {
            setAllCategories(data)
            let newCategory: {label: string, value: string}[] = []
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                newCategory.push({label: element?.name, value: element?._id})
            }
            setAllMainCategories(newCategory.sort((a, b) => {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }))
        })
        .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
        if(category !== "") {
            const filterCategory = allCategories.filter((item: any) => item._id === category)
            
            if (filterCategory.length > 0 && "subCategories" in filterCategory[0]) {
                const subCategories = filterCategory[0].subCategories
                let newSubCategory: string[] = []

                for (let index = 0; index < subCategories.length; index++) {
                    const element = subCategories[index];
                    newSubCategory.push(element)
                }
                setAllSubCategories(newSubCategory.sort((a, b) => {
                    var textA = a.toUpperCase();
                    var textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }))
            }
        }
    }, [category])

    const onSearch = () => {
        if (category && category !== "" && subCategory && subCategory !== "" && search !== "") {
            setSearchParams({search, category: getCategoryName(category), subCategory})
        } else if (category && category !== "" && subCategory && subCategory !== "") {
            setSearchParams({category: getCategoryName(category), subCategory})
        } else if (category && category !== "" && search !== "") {
            setSearchParams({search, category: getCategoryName(category)})
        } else if (subCategory && subCategory !== "" && search !== "") {
            setSearchParams({search, subCategory})
        } else if (search !== "") {
            setSearchParams({search})
        } else if (category && category !== "") {
            setSearchParams({category: getCategoryName(category)})
        } else if (subCategory && subCategory !== "") {
            setSearchParams({subCategory})
        } else {
            setSearchParams()
        }
        scrollTo({y: matches ? 250 : 500})
    }

    const getCategoryName = (category: any) => {
        const filterCategory = allCategories.filter((item: any) => item._id === category)
        return filterCategory[0].name
    }
    
    return (
        <section style={{paddingTop: matches ? "115px" : "202px"}}>
            <Box className={classes.inner}>
                <Carousel
                    dir={i18n.dir() === 'rtl' ? 'rtl' : 'ltr'}
                    // style={{direction: "ltr !important"}}
                    height={matches ? "100%" : "400px"}
                    w={"100%"}
                    slideSize={"100%"}
                    slideGap={0}
                    loop
                    align="center"
                    styles={{controls: {display: 'none'}}}
                    slidesToScroll={1}
                    // plugins={[autoplayHeader.current]}
                >
                    <Carousel.Slide>
                        <Image src={i18n.dir() === 'rtl' ? "/wallpaper_ar.jpg" : "/wallpaper_fr.jpg"} h={matches ? "100%" : "400px"} w={"100%"} fit={matches ? 'contain' : 'fill'} />
                    </Carousel.Slide>
                    {/* <Carousel.Slide>
                        <Image src={i18n.dir() === 'rtl' ? "/wallpaper_ar.jpg" : "/wallpaper_fr.jpg"} h={matches ? "300px" : "400px"} w={"100%"} fit='fill'/>
                    </Carousel.Slide>
                    <Carousel.Slide>
                        <Image src={i18n.dir() === 'rtl' ? "/wallpaper_ar.jpg" : "/wallpaper_fr.jpg"} h={matches ? "300px" : "400px"} w={"100%"} fit='fill'/>
                    </Carousel.Slide> */}
                </Carousel>

                <Box className={classes.boxSearch}>
                    <Box className={classes.boxSearchInner}>
                        {/* <Text c={"#fff"} size={matches ? "26px" : "32px"} ta={'center'} mb={10}>{t('homePage.HeroHeader.title')}</Text>
                        <Text c={"#fff"} size={matches ? "18px" : "24px"} ta={'center'} mb={30}>{t('homePage.HeroHeader.description')}</Text> */}

                        <Grid gutter={5} >
                            <Grid.Col span={{ base: 4,  sm: 4, md: 5 }}>
                                <Select
                                    placeholder={t('homePage.HeroHeader.input01')}
                                    size={matches ? "sm" : 'md'}
                                    value={category} onChange={setCategory}
                                    data={allMainCategories}
                                    clearable={true}
                                    styles={{
                                        dropdown: {
                                            minWidth: 150
                                        },
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 4,  sm: 4, md: 4 }}>
                                <Select
                                    placeholder={t('homePage.HeroHeader.input02')}
                                    size={matches ? "sm" : 'md'}
                                    value={subCategory} onChange={setSubCategory}
                                    data={allSubCategories}
                                    clearable={true}
                                    styles={{
                                        dropdown: {
                                            minWidth: 150
                                        },
                                    }}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 4,  sm: 4, md: 3 }}>
                                <Button variant='filled' color='#000' size={matches ? "sm" : 'md'} fullWidth px={15} onClick={onSearch}>
                                    {t('homePage.HeroHeader.button')}
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <Container size="xl" my={20}>
                <Carousel
                    dir={i18n.dir() === 'rtl' ? 'rtl' : 'ltr'}
                    height={matches ? "60px" : "100px"}
                    slideSize={{ base: '25%', sm: '20%', md: '20%', lg: '20%', xl: '15%' }}
                    slideGap={{ base: 0, sm: 'md' }}
                    loop
                    align="center"
                    styles={{controls: {display: 'none'}}}
                    slidesToScroll={1}
                    plugins={[autoplay.current]}
                    onMouseEnter={autoplay.current.stop}
                    onMouseLeave={autoplay.current.reset}
                >
                    {allCategories.map((item, index) => (
                        <Carousel.Slide key={index}>
                            <Link to={`?category=${item.name}`} preventScrollReset={true} >
                                <Box w={matches ? "60px" : "100px"} h={matches ? "60px" : "100px"} bg={"#fff"}>
                                    <Image src={`https://tajer-api2.qafilaty.com/images/${item.image}`} h={"100%"} w={"100%"} fit='contain' />
                                </Box>
                            </Link>
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Container>
        </section>
    );
}