import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Container, Grid, Group, Image, Stack, Text, Title } from '@mantine/core';
import { FormOrder } from '../components/Sections';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { useTranslation } from 'react-i18next';


export function Product () {
    const autoplay = useRef(Autoplay({ delay: 5000 }));
    let { id } = useParams();
    const [product, setProduct] = useState<any>(null)
    const { t, i18n } = useTranslation();

    useEffect(() => {
        axios.get(`https://tajer-api2.qafilaty.com/api/products/${id}`)
        .then(({data}) => {
            setProduct(data)
        })
        .catch((error) => console.log(error));
    }, [id])

    return (
        <>
            <HeaderSimple />
            <Container size={'lg'} mt={230}>
                <Grid gutter="xl" mt={50}>
                    <Grid.Col span={{ base: 12, sm: 12, md: 5, lg: 5, xl: 5 }}>
                        {product?.imagesProduct?.length > 0
                            ? <Carousel dir='ltr' loop dragFree align="start" plugins={[autoplay.current]} onMouseEnter={autoplay.current.stop} onMouseLeave={autoplay.current.reset}>
                                {product?.imagesProduct.map((item: any, index: number) => (
                                    <Carousel.Slide key={index}>
                                        <Box pos={'relative'}>
                                            <Image src={`https://tajer-api2.qafilaty.com/images/${item}`} alt={""} width={"100%"} fit={'cover'}  />
                                            {product?.priceAfterDiscount > 0
                                                ? <Badge variant="filled" color={"#f78814"} size='lg' radius={0} style={{position: "absolute", top: 0, left: 0, zIndex: 10}}>
                                                        {t('productPage.badge')}
                                                    </Badge>
                                                : null
                                            }
                                        </Box>
                                    </Carousel.Slide>
                                ))}
                            </Carousel>
                            : <Image src={`https://tajer-api2.qafilaty.com/images/${product?.thumbnail}`} alt={""} width={"100%"} fit={'cover'}  />
                        }
                        
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, sm: 12, md: 7, lg: 7, xl: 7 }}>
                        <Stack>
                            <Title order={1} fz={"28px"} fw={700}>
                                {product?.name}
                            </Title>
                            <Text fz={"16px"} c="dimmed">
                                {product?.shortDescription}
                            </Text>
                            <Group gap={10} mt={20}>
                                <Text fz={"24px"} fw={'bold'} c="#f78814">
                                    {product?.priceAfterDiscount > 0 ? product?.priceAfterDiscount : product?.price} {t('productPage.currency')}
                                </Text>
                                {product?.priceAfterDiscount > 0
                                    ? <Text fz={"21px"} fw={'bold'} c="dimmed" td="line-through">
                                        {product?.price} {t('productPage.currency')}
                                    </Text>
                                    : null
                                }
                                {product?.priceAfterDiscount > 0
                                    ? <Badge variant="filled" color={'red'} size='xl' radius={"xs"}>
                                        {(100 - ((product?.priceAfterDiscount * 100) / product?.price)).toFixed(2)}%
                                    </Badge>
                                    : null
                                }
                            </Group>

                            <FormOrder data={product} />
                            <Box mt={30} c={"gray.7"} dangerouslySetInnerHTML={{__html: product?.description}} />

                        </Stack>
                    </Grid.Col>
                </Grid>
            </Container>
            <Footer/>
        </>
    );
}