import { useState } from 'react';
import { Container, Group, Burger, Image, ActionIcon, rem, TextInput, CloseButton, Drawer, Stack, Box, Text, Transition, Divider, Menu, Button, useDirection } from '@mantine/core';
import { useDisclosure, useMediaQuery, useWindowScroll } from '@mantine/hooks';
import classes from './../../styles/HeaderSimple.module.css';
import { IconBrandFacebook, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube, IconChevronDown, IconMapPin, IconPhoneCall, IconPin, IconSearch } from '@tabler/icons-react';
import classesFooter from './../../styles/Footer.module.css';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export function HeaderSimple() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    let location = useLocation();
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const matches = useMediaQuery('(max-width: 36em)');
    const [scroll, scrollTo] = useWindowScroll();
    let [search, setSearch] = useState<string>("");
    let [displaySearchBox, setDisplaySearchBox] = useState(false);
    const { t, i18n, ready } = useTranslation();
    const { dir, setDirection } = useDirection();
  
    const onSearch = () => {
        if (search !== "") {
            setSearchParams({search})
        } else {
            setSearchParams()
        }
        setDisplaySearchBox(false)
    }

    const onChangeLang = (lang: string) => {
        setDirection(i18n.dir(lang));
        const search = searchParams.getAll("search").length > 0 ? {search: searchParams.get("search")} : {}
        const category = searchParams.getAll("category").length > 0 ? {category: searchParams.get("category")} : {}
        const subCategory = searchParams.getAll("subCategory").length > 0 ? {subCategory: searchParams.get("subCategory")} : {}

        //@ts-ignore
        setSearchParams({lang, ...search, ...category, ...subCategory})
        i18n.changeLanguage(lang);
    }

    return (
        <header className={classes.header} style={{minHeight: 80}}>
            <Box bg={"#000"} h={40} display={scroll.y > 10 ? "none" : "block"}>
                <Container size="md" >
                    <Group justify='space-between' align='center' wrap='nowrap'>
                        <Group gap={matches ? "5px" : 'xs'} wrap='nowrap'>
                            <Group gap={2}>
                                {!matches ? <IconPhoneCall color='#fff' size={20} stroke={1} /> : null}
                                <Text c={"#fff"} size={matches ? "12px" : "16px"} component="a" href={"tel:0562982731"}>
                                    0562982731
                                </Text>
                            </Group>
                            <Divider orientation='vertical' color={"#fff"} />
                            <Group gap={2}>
                                {!matches ? <IconPhoneCall color='#fff' size={20} stroke={1} /> : null}
                                <Text c={"#fff"} size={matches ? "12px" : "16px"} component="a" href={"tel:0781686087"}>
                                    0781686087
                                </Text>
                            </Group>
                            <Divider orientation='vertical' color={"#fff"} />
                            <Group gap={2}>
                                <IconMapPin color='#fff' size={matches ? 16 : 20} stroke={1} />
                                
                                <Text c={"#fff"} size={matches ? "12px" : "16px"} component="a" href={"https://maps.app.goo.gl/BB6cSth8pexb7eVT7"}>
                                    {t('homePage.HeaderSimple.address')}
                                </Text>
                            </Group>
                        </Group>

                        <Menu shadow="md" width={120}>
                            <Menu.Target>
                                <Button variant="transparent" c={"#fff"} px={'xs'}>
                                    {t('homePage.HeaderSimple.currentLang')}
                                    <IconChevronDown size={16} />
                                </Button>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item onClick={() => onChangeLang("fr")}>{t('homePage.HeaderSimple.listLanguages.francais')}</Menu.Item>
                                <Menu.Item onClick={() => onChangeLang("ar")}>{t('homePage.HeaderSimple.listLanguages.arabic')}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </Container>
            </Box>
            <Container size="md" className={classes.inner}>
                <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
                <Image src={"/logo.png"} height={70} style={{cursor: "pointer"}} onClick={() => navigate("/")} />
                <Group justify='center' align='center' gap={0} visibleFrom="xs" display={location.pathname !== "/" ? "none" : "flex"}>
                    <TextInput 
                        variant="filled" radius={0} placeholder={t('homePage.HeaderSimple.navBar.searchInput')}
                        value={search}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                        leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} />}
                        className={classes.inputSearch}
                    />
                    <ActionIcon variant='filled' color='#000' radius={0} size={'lg'} onClick={onSearch}>
                        <IconSearch color='#fff' size={20}  />
                    </ActionIcon>
                </Group>
                <Group  visibleFrom="md" gap={5} className={classesFooter.social} justify="flex-end" wrap="nowrap">
                    <ActionIcon size="md" color="gray" variant="outline" radius={20}
                        component='a'
                        href='https://www.tiktok.com/@bougie.plus?_t=8lZhV6Ps1Ze&_r=1'
                    >
                        <IconBrandTiktok style={{ width: rem(15), height: rem(15) }} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon size="md" color="gray" variant="outline" radius={20}
                        component='a'
                        href='https://www.facebook.com/bougieplus16?mibextid=ZbWKwL'
                    >
                        <IconBrandFacebook style={{ width: rem(15), height: rem(15) }} stroke={1.5} />
                    </ActionIcon>
                    <ActionIcon size="md" color="gray" variant="outline" radius={20}>
                        <IconBrandInstagram style={{ width: rem(15), height: rem(15) }} stroke={1.5} />
                    </ActionIcon>
                </Group>

                <ActionIcon hiddenFrom="md" variant='transparent' color='#000' radius={0} size={'lg'} onClick={() => setDisplaySearchBox(true)}>
                    <IconSearch color='#000' size={24}  />
                </ActionIcon>
            </Container>

            <Box mih={40} mt={15} pt={5} visibleFrom="sm" style={{borderTop: "1px solid #dee2e6"}}>
                <Container size="md" >
                    <Group justify='center' align='center'>
                        <Link to="/" className={classes.link}>
                            {t('homePage.HeaderSimple.navBar.links.link01')}
                        </Link>
                        <Link to="/categories" className={classes.link}>
                            {t('homePage.HeaderSimple.navBar.links.link02')}
                        </Link>
                        <Link to="/products" className={classes.link}>
                            {t('homePage.HeaderSimple.navBar.links.link03')}
                        </Link>
                    </Group>
                </Container>
            </Box>
            
            <Transition
                mounted={displaySearchBox}
                transition="slide-down"
                duration={500}
                timingFunction="ease"
            >
                {(styles) => (
                    <Box
                        style={{...styles, zIndex: 10}}
                        mih="80px" py={20} px={15} hiddenFrom="md" bg={"#fff"}
                        pos={'fixed'} top={0} right={0} left={0}
                        display={displaySearchBox ? 'block' : "none"}
                    >
                        <CloseButton 
                            size={'lg'} pos={'absolute'} top={15} right={20}
                            onClick={() => setDisplaySearchBox(false)}
                        />
            
                        <Text c={"#000"} size={"20px"} ta={'center'} mb={20}>{t('homePage.HeaderSimple.navBar.searchTitle')}</Text>
                        <Group justify='center' align='center' gap={0} display={location.pathname !== "/" ? "none" : "flex"} wrap='nowrap'>
                            <TextInput 
                                variant="filled" radius={0} placeholder={t('homePage.HeaderSimple.navBar.searchInput')}
                                size={'lg'}
                                value={search}
                                onChange={(event) => setSearch(event.currentTarget.value)}
                                leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} />}
                                className={classes.inputSearch}
                            />
                            <ActionIcon variant='filled' color='#000' radius={0} miw={50} h={50} onClick={onSearch}>
                                <IconSearch color='#fff' size={20}  />
                            </ActionIcon>
                        </Group>
                    </Box>
                )}
            </Transition>


            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="80%"
                padding="md"
                title={
                    <Image src={"/logo.jpg"} height={55} style={{cursor: "pointer"}} onClick={() => navigate("/")} />
                }
                hiddenFrom="sm"
                zIndex={1000000}
            >
                <Stack pt={20} h={"100%"} gap={0}>
                    <Link to="/" className={classes.link}>
                        {t('homePage.HeaderSimple.navBar.links.link01')}
                    </Link>
                    <Link to="/categories" className={classes.link}>
                        {t('homePage.HeaderSimple.navBar.links.link02')}
                    </Link>
                    <Link to="/products" className={classes.link}>
                        {t('homePage.HeaderSimple.navBar.links.link03')}
                    </Link>
                </Stack>
            </Drawer>
        </header>
    );
}