import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    Stack,
    Loader,
    Image,
    Button,
  } from '@mantine/core';
  import { IconGauge, IconUser, IconCookie } from '@tabler/icons-react';
  import classes from './../../styles/Products.module.css';
import { ProductCard } from '../Cards';
import useAxios from 'axios-hooks'
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { searchSortedData } from '../../lib/sort';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
  
export function Products(props: any) {
    const theme = useMantineTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    let [allProducts, setAllProducts] = useState<any>([]);
    let [filterdAllProducts, setFiltredAllProducts] = useState<any>([]);
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [{ data: dataAllProducts, loading, error }, refetch] = useAxios(
      `https://tajer-api2.qafilaty.com/api/posting-products/?page=${page}&limit=10&sort=-1`,
    )
    const matches = useMediaQuery('(max-width: 36em)');

    useEffect(() => {
      if (dataAllProducts && dataAllProducts.data.length >= 0) {
        setAllProducts([...allProducts, ...dataAllProducts.data])
        setFiltredAllProducts([...filterdAllProducts, ...dataAllProducts.data])
        setTotalPages(dataAllProducts.totalPages)
      }
    }, [dataAllProducts])

    useEffect(() => {
        const search = searchParams.get("search") || null
        const category = searchParams.get("category") || null
        const subCategory = searchParams.get("subCategory") || null
        if (allProducts && allProducts.length > 0 && (search || category || subCategory)) {
            if (search && category && subCategory) {
                let newData = allProducts?.filter((item: any) => item.categories.includes(category) && item.subCategories.includes(subCategory))
                newData = searchSortedData(newData, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (category && subCategory) {
                let newData = allProducts?.filter((item: any) => item.categories.includes(category) && item.subCategories.includes(subCategory))
                setFiltredAllProducts(newData)
            } else if (search && category) {
                let newData = allProducts?.filter((item: any) => item.categories.includes(category))
                newData = searchSortedData(newData, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (search && subCategory) {
                let newData = allProducts?.filter((item: any) => item.subCategories.includes(subCategory))
                newData = searchSortedData(newData, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (search) {
                let newData = searchSortedData(allProducts, ["name"], search)
                setFiltredAllProducts(newData)
            } else if (category) {
                let newData = allProducts?.filter((item: any) => item.categories.includes(category))
        
                console.log({allProducts, newData});
                
                setFiltredAllProducts(newData)
            } else if (subCategory) {
                let newData = allProducts?.filter((item: any) => item.subCategories.includes(subCategory))
                setFiltredAllProducts(newData)
            }
        } else {
          setFiltredAllProducts(allProducts)
        }
    }, [allProducts, searchParams.get("search"), searchParams.get("category"), searchParams.get("subCategory")])

    const loadMore = () => {
      setPage(page + 1);
    };

    if (error) return <p>Error!</p>
    
    return (
      <Container size="lg" pb="xl" mt={0} {...props}>
        <Title order={2} className={classes.title} ta="center" mt="sm">
          {searchParams.get("search") || searchParams.get("category") || searchParams.get("subCategory")
            ? t('homePage.Products.title02')
            : t('homePage.Products.title')
          }
        </Title>
        {/* <Text c="dimmed" className={classes.description} ta="center" mt="md" fw={"bold"}>
          {t('homePage.Products.description')}
        </Text> */}
  
        {loading
          ? <Stack align='center' justify='center' mt={50}>
              <Loader color="#f78814" size="md" type="bars" />
            </Stack>
          : null
        }
        
        {filterdAllProducts && filterdAllProducts?.length > 0
          ? <>
            <SimpleGrid cols={{ base: 2, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={matches ? "xs" : "xl"} mt={50}>
              {filterdAllProducts?.map((item: any, index: number) => (
                  <ProductCard
                    key={index}
                    id={item._id}
                    title={item?.name}
                    image={`https://tajer-api2.qafilaty.com/images/${item?.thumbnail}`}
                    price={item?.price }
                    priceAfterDiscount={item.priceAfterDiscount}
                  />
              ))}
            </SimpleGrid>
            
            {filterdAllProducts.length < totalPages
              ? <Group justify='center' align='center' mt={30}>
                <Button variant='light' color='#f78814' onClick={loadMore}>عرض المزيد</Button>
              </Group>
              : null
            }
          </>
          : <Stack align='center' justify='center' mt={50}>
              <Image src={"/shopping-trolley.png"} h={"100px"} w={"100px"} fit='contain' />
            </Stack>
        }
      </Container>
    );
  }