import './styles/App.css';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { Home, Product, LandingProduct, Categories, ProductsPage } from './pages';
import ReactPixel from 'react-facebook-pixel';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './lib/i18n';
import { useTranslation } from 'react-i18next';
import { DirectionProvider, MantineProvider, createTheme } from '@mantine/core';

const theme = createTheme({
    fontFamily: 'IBM Plex Sans Arabic, sans-serif',
});

function App() {
    const [facebookToken, setFacebookToken] = useState("")
    const { i18n } = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        axios.get(`https://tajer-api2.qafilaty.com/api/pixels`)
        .then(({data}) => {
            const facebookData = data.filter((item: any) => item.name === "facebook")
            setFacebookToken(facebookData?.[0]?.apiKey)
        })
        .catch((error) => console.log(error));

        if(searchParams.getAll("lang").length > 0) {
            //@ts-ignore
            i18n.changeLanguage(searchParams.get("lang"));
        } else {
            i18n.changeLanguage("fr");
        }
    }, [])

    useEffect(() => {
        if (facebookToken !== "") {
            ReactPixel.init(facebookToken);
            ReactPixel.pageView();
        }
    }, [facebookToken])

    return (
        <DirectionProvider initialDirection={i18n.dir()}>
            <MantineProvider theme={theme}>
                <Routes>
                    <Route index path="/" element={<Home />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/products" element={<ProductsPage />} />
                    <Route path="/product/:id" element={<Product />} />
                    <Route path="/:id" element={<LandingProduct />} />
                </Routes>
            </MantineProvider>
        </DirectionProvider>
    );
}

export default App;
